@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&family=Tilt+Neon&display=swap');

*{
    padding: 0;
    margin: 0;
    font-family: 'Tilt Neon' ;
    cursor: url(../img/huella.png), auto;
    scroll-behavior: smooth;
  }
  
  body{
    overflow-x: hidden;
  font-size: 18px;
}
/* Estilos para el cursor tipo huella de dinosaurio*/
a:hover,button:hover{
    cursor: url(../img/huella_dino3.png), auto;
}

.contenedor-inicio,.contenedor-experencias,.contenedor-nosotros{
    background-image: url(../img/fondo_prehistorico.jpg);
   /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.imonster{
    font-family: 'Creepster',cursive;
    letter-spacing: 2px;
}

/*Estilos de la caja de botones para las redes sociales*/

.contenedor-iconos{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom:10px;
    right:10px;
    width: 100px;
    z-index: 10000000;
}
.iconos-sociales{
    width: 100%;
    height: 45px;
    position: relative;
    padding:4px;


    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: contain;
}

.i1{
    background-image: url('../img/facebookBoton.png');
}

.i2{
    background-image: url('../img/InstragramBoton.png'); 
}
.i3{
    background-image: url('../img/whatsappBoton.png');  
}

/* Animación de los botones*/

@keyframes shake {
    0% { transform: translate(0, 0); }
    25% { transform: translate(-5px, 5px); }
    50% { transform: translate(5px, -5px); }
    75% { transform: translate(-5px, 5px); }
    100% { transform: translate(0, 0); }
}
  
.iconos-sociales:hover {
    animation: shake 0.5s infinite;
}

/*Colores institucionales */
.naranja{
    background-color: #F07F1A;
    
  }
  .gradientna{
    background: rgb(240,127,26);
    background: linear-gradient(93deg, rgba(240,127,26,1) 12%, rgba(255,255,255,0.011642156862745057) 98%);
  }
  .col-nrj{
    color:#F07F1A;
  }
  .col-ama{
    color:#FADD66;
  }
  .cafe{
    background-color: #68420F;
  }
  .rojo{
    background-color: #E52621;  
  }
  .amarillo{
    background-color: #FADD66;
  }
  
  .itemSe{
    text-decoration: none;
    color: black;
    font-family:'Creepster', cursive;
    letter-spacing: 1px;
    font-weight: bold;
    height:  80px;
    width: 170px;  
    font-size: 20px;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../img/reserva.png);
    background-position: center;
    background-size: contain;

    background-repeat: no-repeat;
    text-align: center;
    transition: all ease-out 0.3s ;
}