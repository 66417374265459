
.Footer{
    background-image: url(../img/footer.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 
    min-height:30vh;
    width: 100%;
 

    font-size: 18px;
}

.Footer>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    gap: 4px;
    padding: 20px 0px;
}

.Footer>div>p:first-child{
    font-weight: bold;
}

.Footer>div>svg{
    height: 80px;
    width:60px;

}

.text{
    line-height: 13px;
}
