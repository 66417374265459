*{
    padding: 0;
    margin:0;
}

.contenedor-inicio{
   
    min-height: 90vh;
    width: 100%;
    padding: 20px 50px;
    background-color: #FAD66f;
    display: flex;
    flex-direction: column;
   
    align-items: center;
    font-family: verdana;
}

.contenedor-video{
    margin-top: 10px;
    padding: 10px 20px;
    background-color:white ;
    max-width: 50%;
    border-radius: 20px;
}

.video{
   object-fit: fill;
    width: 100%;
    height: 100%;
}